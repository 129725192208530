




















































































































































































































































































































































































import EventBus from '@/common/EventBus';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State, Getter } from 'vuex-class';
import _ from 'lodash';
import config from '@/config';

import FactDate from '@/components/common/form/fact/FactDate.vue';
import FactText from '@/components/common/form/fact/FactText.vue';
import FactAgree from '@/components/common/form/fact/FactAgree.vue';
import FactMultilineText from '@/components/common/form/fact/FactMultilineText.vue';
import FactNumber from '@/components/common/form/fact/FactNumber.vue';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactOption from '@/components/common/form/fact/FactOption.vue';
import FactLabel from '@/components/common/form/fact/FactLabel.vue';
import FactList from '@/components/common/form/fact/FactList.vue';
import CustomMultiBoolean from '@/components/common/form/fact/CustomMultiBoolean.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';
import FactAddressAutoComplete from '@/components/common/form/fact/FactAddressAutoComplete.vue';
import FactCompanyAutoComplete from '@/components/common/form/fact/FactCompanyAutoComplete.vue';
import Benefits from '@/components/products/Benefits.vue';
import Section from '@/components/common/Section.vue';
import {FactService, COPY_MAIN_DETAILS_SECTION, MAIN_SECTION_NAME, NAME_ADDRESS_FIELD_SUFFIXES, ADDRESS_FIELD_SUFFIXES} from '@/services/FactService';
import utils from './Utils';
import Util from '@/utils/Util';
import GAUtil from '@/utils/GAUtil';
import {BUSINESS_SECTION_NAME} from '@/utils/ProductEngineUtils';
import {
  IQuotation,
  IFactUpdatePayload,
  IShiftProductData,
  IMultiFactUpdatePayload,
  IFactObject,
  IAddInsuredPayload,
  IRemoveInsuredPayload,
  IApp, ICMS, IProposal, IAuth
} from '@/interfaces';
import QuoteProduct from '@/models/QuoteProduct';
import Illustration from '@/components/common/Illustration.vue';
import GeneralInformation from '@/views/auth/Portal/PortalStepperItems/GeneralInformation.vue';
import FactInfo from '@/components/common/form/fact/FactInfo.vue';

const CGL_DETAILS_SECTION = 'CGL_DETAILS_SECTION';

const SHIFT_ASSET_LOCATION = 'LOCATION';
const FIRST_NON_TECHNICAL_ERROR = 100;
const INSURED_TYPES_HEADING = {
  worker : 'product.coverageDetails.headers.workers',
  item : 'product.coverageDetails.headers.items',
  additionalInsured: 'product.coverageDetails.headers.additionalInsured',
  equine: 'product.coverageDetails.headers.equine'
};

const STEP = 'coverageDetails';

@Component({
  name: 'CoverageDetails',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactList,
    FactLabel,
    FactTooltip,
    FactSelection,
    FactOption,
    FactDate,
    FactText,
    FactAgree,
    FactMultilineText,
    FactNumber,
    FactAddressAutoComplete,
    FactPhoneNumber,
    FactInfo,
    Benefits,
    Section,
    FactCompanyAutoComplete,
    CustomMultiBoolean,
    Illustration,
    GeneralInformation
  },
})
export default class CoverageDetails extends Vue {
  @Prop() private next: any;
  @Prop() private img: any;
  @Prop() private detailCopiesInitial: any;
  @State private auth!: IAuth;
  @State private proposal!: IProposal;
  @State private quotation!: IQuotation;
  @State private app!: IApp;
  @State private cms!: ICMS;
  @Action('app/setPardotAvailability') private setPardotAvailability: any;
  @Action('quotation/updateFact') private updateFact!: (payload: IFactUpdatePayload) => void;
  @Action('quotation/updateMultiFact') private updateMultiFact!: (payload: IMultiFactUpdatePayload) => void;
  @Action('quotation/addInsured') private addInsured!: (payload: IAddInsuredPayload) => void;
  @Action('quotation/removeInsured') private removeInsured!: (payload: IRemoveInsuredPayload) => void;
  @Getter('quotation/getCoverageDetailFacts') private getCoverageDetailFacts!: () => any;
  @Getter('quotation/getAllCustomProductTrees') private getAllCustomProductTrees!: () => any;
  @Getter('quotation/insuredTypes') private insuredTypes!: () => any;
  @Getter('quotation/getFactValidationData') private getFactValidationData!: (productID: string, validatedFacts: string[]) => any[];
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => QuoteProduct[];
  @Getter('quotation/getFactValueByFactID') private getFactValueByFactID!: (factID: string) => string;
  @Getter('quotation/getFactByFactID') private getFactByFactID!: (factID: string) => any;
  @Getter('quotation/getAssetLevelFacts') private getAssetLevelFacts!: () => any[];

  private detailCopies = {};
  private lastTabIndex = 0;
  private abnDisabled = false;

  private insuredAssetsUpdate: any[] = [];

  public async validate(this: any) {
    this.$resetErrors();
    // todo update validations

    // if (!this.app.isRenewal) {
    //   let isRenewal = null;
    //   _.forEach(this.sessions, (session) => {
    //     const temp = ProductEngineUtils.getFactValue(session, Util.COMPONENT_RENEWAL_ID);
    //     if (temp != null && isRenewal === null) {
    //       isRenewal = temp;
    //     }
    //   });
    //   if (isRenewal != null) {
    //     this.updateFn({id: Util.COMPONENT_RENEWAL_ID}, isRenewal);
    //   }
    // }
    this.$validator.validate()
      .then(async (result: any) => {
        let hasShiftErrors = false;
        _.forEach(this.quotation.products, async (product: IShiftProductData) => {
          const shiftResult = this.getFactValidationData(product.id, this.usedFactIds);
          _.forEach(shiftResult, (err: any) => {
            hasShiftErrors = true;
            let factCode = Util.generateUid(err.productId, err.error.relatedFacts[0]);

            const message = err.error.code < FIRST_NON_TECHNICAL_ERROR ? this.$t('validations.shift.' + err.error.message) : err.error.message;
            if (!_.includes(this.theAssetLevelFactIds, err.error.relatedFacts[0])) {
              factCode = Util.generateUid(err.error.relatedFacts[0], err.error.relatedFacts[0]);
            }
            this.$addError(factCode, message);
          });
        });

        if ((result === true || result.valid === true) && !hasShiftErrors) {
          this.sendToCRM();
          if (!this.proposal.basicInfo) {
            GAUtil.ODLogUserData();
          }
          await GAUtil.ODLogUserData();
          this.$global.leaveAnimation();
          this.next();
        } else {
          Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
              event_params : {
                error_type: 'form-error',
                error_message:  'Please provide valid input'
              }
          });
          this.$emit('error', this.xxErrors);
        }
      });
  }

  get copyFieldsSuffixes() {
    if (sessionStorage.targetSegment === 'PRIV') {
      return _.get(this.app.config.shiftMappings, 'COPY_SUFFIXES', NAME_ADDRESS_FIELD_SUFFIXES);
    } else { return _.get(this.app.config.shiftMappings, 'COPY_SUFFIXES', ADDRESS_FIELD_SUFFIXES); }
  }

  get theAssetLevelFactIds() {
    return this.getAssetLevelFacts().map((asset: any) => asset.id);
  }

  // Get all thr factsID rendered on coverage detail step
  get usedFactIds() {
    const allowed: any = [];
    Object.keys(this.uniqueFacts).forEach((sectionName: string) => {
      const section = this.uniqueFacts[sectionName];

      if (section && this.shouldBeDisplayed(sectionName, section)) {
        allowed.push(section.map((fact: any) => fact.id));
      }
    });

    return [..._.flatten(allowed), ...this.theAssetLevelFactIds];
  }

  get namedFactGroups() {
    return FactService.namedFactGroups();
  }

  get manualFactGroups() {
    return FactService.manualFactGroups();
  }

  private sectionConfig(sectionName: string, component) {
    return _.get(this.app.config.shiftMappings, `SECTION_GROUPS_CONFIG[${sectionName}]`) || {};
  }

  get productTrees() {
    return this.getAllCustomProductTrees();
  }

  get uniqueFacts() {
    return this.getCoverageDetailFacts();
  }

  private getFactGroupText(group) {
    return utils.getGroupText(this, group, STEP, group.root && group.root._productCode, this.app.config.shiftMappings);
  }

  private registerClass(fact, productCode) {
    utils.registerClass(this.app.config.shiftMappings, fact, productCode, true);
  }

  private getFactGroups(facts: any[]) {
    facts = utils.customModeler(facts, _.get(this.app.config.shiftMappings, 'CUSTOM_MODEL_FACTS', {}));
    return utils.getFactGroups(facts, this.namedFactGroups, this.manualFactGroups);
  }

  private getFactGroupsName(facts: any[]) {
    const factGroups: any = this.getFactGroups(facts);
    const names = factGroups.map((g: any) => g.name);
    return names;
  }

  private isOmittedLocationGroup(groupNames: string[]) {
    const omittedNames = _.get(this.app.config.shiftMappings, 'OMITTED_LOCATION_GROUPS_CONFIG', []);

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < groupNames.length; i++) {
      const name = groupNames[i];
      if (omittedNames.includes(name)) {
        return true;
      }
    }
    return false;
  }

  private getFormattedFactGroups(facts: any[]) {
    const groups = this.getFactGroups(facts);
    return groups.filter((g) => {
      g.facts.forEach((f) => {
        if (f.type === 'GROUP') {
          g.name = f.name;
        }
      });
      g.mandatory = !!g.facts.find((f) => f.mandatory);
      return g.facts.length > 0;
    });
  }

  private getInsuredTypeHeading(type: string) {
    return INSURED_TYPES_HEADING[type];
  }

  private created() {
    EventBus.$on('TreeUpdated', _.debounce((id) => {
      EventBus.$emit('TreeUpdated-Coverage', id);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS));
    this.initStep();
  }

   private mounted() {
    setTimeout(() => {
      this.tabIndexes();
      this.initAdressCopies();
    }, 0);
  }

  private activated() {
    // EventBus.$on('TreeUpdated-Coverage', (id) => {
    // });
    EventBus.$on('priceChange', () => {
      this.$dialog.open({type: 'price-change'});
    });
    this.initStep();
    this.$global.enterAnimation();
    Util.gaLogPageView(this, `/coverage+${sessionStorage.subSegment ? sessionStorage.subSegment : sessionStorage.targetSegment}`);
    this.updateAssets();
    // this.onAddressCopy();
    this.$nextTick(() => {
      EventBus.$emit('stepper-idle');
      EventBus.$emit('TreeUpdated-Coverage');
    });

    GAUtil.ODLogProductsCoverageDetails();

    // copy address again when component activated and detail copies exist
    if (!_.isEmpty(this.detailCopies)) {
      this.cloneSectionFacts(this.detailCopies, this.copyFieldsSuffixes);
    }
  }

  private deactivated() {
    EventBus.$off('TreeUpdated-Coverage');
    EventBus.$off('priceChange');
    EventBus.$emit('stepper-busy');
  }

  private initStep() {
    this.abnDisabled = !_.isEmpty(this.app.config.companyComplete);
  }

  private shouldUseSection(sectionaName: string) {
    return ['primarylocation', 'bpldetails'].includes(sectionaName);
  }

  private sectionTitle(sectionName: string) {
    const sectionTitle: string = FactService.sectionGroupNameOverwrite(sectionName, this.proposal.products.length > 1, _.get(this.proposal, 'products[0].code', ''));
    return this.$t(`product.coverageDetails.headers.${sectionTitle}`);
  }

  private isNumericText(fact) {
    return FactService.isNumericText(fact);
  }

  private isReadOnly(fact) {
      const readonlySections = this.app.config.shiftMappings.COVERAGE_DETAILS_READONLY_SECTIONS || [];
      return readonlySections.find((sectionPrefix) => fact.id.startsWith(sectionPrefix)) || (this.auth.guestParty && fact.id.startsWith('applicant'));
  }

  private shouldBeDisplayed(sectionName: string, sectionFacts: any) {
    if (this.shouldUseSection(sectionName)) {
      return false;
    }
    const COVERAGE_DETAILS_SECTIONS_ORDER = 'COVERAGE_DETAILS_SECTIONS_ORDER';
    const order = this.app.config.shiftMappings[COVERAGE_DETAILS_SECTIONS_ORDER] || [];
    const groups = order.includes(sectionName) && this.getFactGroups(sectionFacts);
    return groups && groups.some((g) => g.facts.some((f) => f.type !== 'GROUP'));
  }

  private hasAddTitle(insuredType: any) {
    const translated = this.$t('proposal.configuration.addTitle.' + insuredType.type);
    return translated !== ('proposal.configuration.addTitle.' + insuredType.type);
  }

  private hasShiftAssetLocation(type) {
    return type.toUpperCase() === SHIFT_ASSET_LOCATION;
  }

  private canAddAsset(productTree: any, assetType: any) {
    const assets = _.filter(_.get(productTree, 'assets', []), (asset: any) => {
      return asset.type === assetType.type;
    });
    return assets.length < assetType.maxAllowed;
  }

  private canRemoveAsset(productTree: any, assetType: any) {
    const assets = _.filter(_.get(productTree, 'assets', []), (asset: any) => {
      return asset.type === assetType.type;
    });
    return assets.length > assetType.minAllowed;
  }

  private addAsset(id: string, insuredType: any) {
    this.addInsured({productID: id, type: insuredType.type, callback: () => this.updateAssets()});
  }

  private removeAsset(prodId: string, id: string, type: string, insuredType: any) {
    this.removeInsured({productID: prodId, type, insuredID: id, callback: () => this.updateAssets()});
  }

  private updateAssets() {
    this.insuredAssetsUpdate = [];
    for (const insuredType of this.insuredTypes()) {
      if (!insuredType) {
        continue;
      }
      this.insuredAssetsUpdate.push(insuredType);
    }
  }

  private hasCoverageInsuredType(insuredType) {
    return FactService.hasCoverageInsuredType(insuredType);
  }

  private getAssetByType(assets: any[], type: string) {
    return _.filter(assets, (asset: any) => {
      return asset.type === type;
    });
  }

  private assetGroupsFacts(asset) {
    return FactService.assetGroupsFacts(asset);
  }

  private getAssetIndex(max: number, index: number, type: string) {
    return max > 1 ? ('#' + ((index || 0) + (type === 'secondary' ? 2 : 1))) : '';
  }

  private isCopyDetailsSection(sectionName) {
    const sections = _.get(this.app.config.shiftMappings, 'SECTIONS_DISABLED_DETAILS_COPY', []);
    const explicitSections = _.get(this.app.config.shiftMappings, 'SECTIONS_ENABLE_DETAILS_COPY', []);
    return (sectionName !== MAIN_SECTION_NAME || explicitSections.includes(sectionName)) && !sections.includes(sectionName);
  }

  private initAdressCopies() {
    let detailCopies = {};
    const sections = _.get(this.app.config.shiftMappings, 'SECTIONS_DISABLED_DETAILS_COPY', []);
    const explicitSections = _.get(this.app.config.shiftMappings, 'SECTIONS_ENABLE_DETAILS_COPY', []);
    if (_.isEmpty(this.detailCopiesInitial)) {
      _.forEach(this.uniqueFacts, (sectionFacts, sectionName) => {
        if (_.get(sectionFacts, 'length') > 0 && (sectionName !== MAIN_SECTION_NAME || explicitSections.includes(sectionName)) && !sections.includes(sectionName)) {
          detailCopies[sectionName] = !_.some(sectionFacts, 'currentValue') && sectionName !== BUSINESS_SECTION_NAME && (_.get(this.app.config.shiftMappings, `${COPY_MAIN_DETAILS_SECTION}.DEFAULT`) || false);
        }
      });
    } else {
      detailCopies = this.detailCopiesInitial;
    }
    this.detailCopies = detailCopies;
    setTimeout(() => {
      this.$global.enterAnimation();
    }, 0);
  }

  private isAbnDisabled(fact: any) {
    return fact.id === 'business.abn' && this.abnDisabled;
  }

  private isPhoneType(field: any) {
    return (!!field.type.match(Util.COMPONENT_PHONE_TYPE_ID));
  }

  private updateAllProducts() {
    let facts: any = [];
    _.forEach(this.uniqueFacts, (value) => {
      facts = facts.concat(value);
    });
    facts = _.keyBy(facts, 'id');
  }

  private mergedComponents(product: any) {
    return product.detailFacts ? [{detailFacts: product.detailFacts}].concat(product.components) : product.components;
  }

  private isCglDetailsFact(product: any, fact: any) {
    const id = (fact.id.match(/^PRODUCT:/) ? '' : 'PRODUCT:' + product.code + '#') + fact.id;
    return _.get(this.app.config.shiftMappings, CGL_DETAILS_SECTION, []).includes(id);
  }

  private isFullWidth(fact: any, group: any) {
    return (group && group.header && group.facts.length === 1 && _.get(this.app.config.shiftMappings, `MANUAL_FACT_GROUP_COLUMNS.${group.name}`, 1) === 1) || (fact.formatType === 'checkbox' || fact.formatType === 'group') ||
      (fact.type === 'LIST' &&  fact.options.length < parseInt(_.get(config, 'layout.listLimit', '0'), 10));
  }

  private onPanelChange(e) {
    setTimeout(() => {
      this.tabIndexes();
    }, 0);
  }

  private tabIndexes() {
    const list = document.querySelectorAll('[aria-expanded="true"] input[tabindex]:not([disabled="disabled"])');
    this.lastTabIndex = list.length;
  }

  private isCglSection(sectionName: string) {
    return ['cgldetails', 'cgl'].includes(sectionName);
  }

  get hasCglDetailsFacts() {
    const ids = _.get(this.app.config.shiftMappings, CGL_DETAILS_SECTION, []);
    const regexrs = _.map(this.quotation.products, (p) => '^PRODUCT:' + p.code + '#');
    return ids.find((id) => _.find(regexrs, (regex) => id.match(regex)));
  }

  get mainSection() {
    return _.get(this.app.config.shiftMappings, 'COPY_MAIN_DETAILS_SECTION.ID') || MAIN_SECTION_NAME;
  }

  @Watch('detailCopies', {deep: true})
  private onAddressCopy() {
    this.cloneSectionFacts(this.detailCopies, this.copyFieldsSuffixes);
  }

  private cloneSectionFacts(toCopyToSections: any, suffixes: string[], payload?: any) {
    const factsToUpdate: any[] = [];
    let mainSectionToUpdate: any[] = [];
    if (payload) {
      if (payload.id && payload.value) {
        factsToUpdate.push({
          factID: payload.id,
          factValue: payload.value
        });
      }
      if (payload.data) {
        mainSectionToUpdate = payload.data;
      }
    }
    _.forEach(this.uniqueFacts, (sectionFacts, sectionName) => {
      if (toCopyToSections[sectionName]) {
        const mainFacts = this.uniqueFacts[this.mainSection];
        _.forEach(suffixes, (field: string) => {
          const originalFact: any = _.find(mainFacts, (f: any) => f.id && f.id.endsWith(field));
          const destinationFact: any = _.find(sectionFacts, (f: any) => f.id && f.id.endsWith(field));
          if (!_.isEmpty(originalFact) && !_.isEmpty(destinationFact)) {
            const toValue = (originalFact && !_.isEmpty(mainSectionToUpdate) && _.get(_.find(mainSectionToUpdate, (f: any) => f.factID === originalFact.id), 'factValue')) || originalFact.currentValue;
            const existing: any = _.find(factsToUpdate, (f) => f.factID === destinationFact.id);
            if (existing) {
              existing.factValue = (payload && payload.value) || toValue;
            } else {
              factsToUpdate.push({factID: destinationFact.id, factValue: (payload && payload.value) || toValue});
            }
          }
        });
      }
    });

    this.proposal.products.forEach((product) => {
      this.updateMultiFact({
        productID: product.id,
        productCode: product.code,
        facts: factsToUpdate
      });
    });
  }

  private hasOtherLocationFacts(sectionName) {
    return this.uniqueFacts[sectionName].length > 0;
  }

  private updateFn(factData: any, factValue: any) {
    const factId = factData.id.split('#').pop();
    const suffix = _.find(this.copyFieldsSuffixes, (s: any) => factId === this.mainSection + s);
    if (suffix) {
      this.cloneSectionFacts(this.detailCopies, [suffix], {id: factId, value: factValue});
    } else {
      this.updateFact({
        productID: factData._productID,
        fact: factData,
        value: factValue
      });
    }
  }

  private getAssetUpdateFn(factData: any, factValue: any) {
    this.updateFact({
      productID: factData._productID,
      fact: factData,
      value: factValue
    });
  }

  private selectedSecondaryAddress(productId: string, originatingFactId: string, address: any) {
    const fields = utils.getAddressFields(originatingFactId, address, _.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', {}));
    const factsDataToUpdate: IFactObject[] = [];
    fields.forEach((fact) => {
      let val = fact.val[0];
      // handler for list search val / name
      let realFact: any = null;
      _.forEach(_.values(this.uniqueFacts), (section: any) => _.forEach(section, (f) => {
        if (f.id === fact.id || f.id.endsWith(fact.id)) {
          realFact = f;
          return false;
        }
      }));
      if (realFact && realFact.formatType === 'list') {
        const option = _.find(realFact.options, (opt) => _.values(fact.val).includes(opt.value) || _.values(fact.val).includes(opt.name));
        if (option) {
          val = option.value;
        }
      }
      if (realFact) {
        factsDataToUpdate.push({
          factID: realFact.id,
          factValue: val
        });
      }
    });
    this.proposal.products.forEach((product) => {
      this.updateMultiFact({
        productID: product.id,
        productCode: product.code,
        facts: factsDataToUpdate
      });
    });
  }

  private selectedPrimaryAddress(productId: string, originatingFactId: string, address: any) {
    const fields = utils.getAddressFields(originatingFactId, address, _.get(this.app.config.shiftMappings, 'ADDRESS_AUTO_COMPLETE', {}));
    const factsDataToUpdate: IFactObject[] = [];
    fields.forEach((fact) => {
      let val = fact.val[0];
      // handler for list search val / name
      let realFact: any = null;
      _.forEach(_.values(this.uniqueFacts), (section: any) => _.forEach(section, (f) => {
        if (f.id === fact.id || f.id.endsWith(fact.id)) {
          realFact = f;
          return false;
        }
      }));
      if (realFact && realFact.formatType === 'list') {
        const option = _.find(realFact.options, (opt) => _.values(fact.val).includes(opt.value) || _.values(fact.val).includes(opt.name));
        if (option) {
          val = option.value;
        }
      }
      if (realFact) {
        factsDataToUpdate.push({
          factID: realFact.id,
          factValue: val
        });
      }
    });
    this.proposal.products.forEach((product) => {
      this.updateMultiFact({
        productID: product.id,
        productCode: product.code,
        facts: factsDataToUpdate
      });
    });
    setTimeout(() => {
      this.cloneSectionFacts(this.detailCopies, this.copyFieldsSuffixes, {data: factsDataToUpdate});
    }, 500);
  }

  private selectedCompany(abn: string) {
    const factData = this.getFactByFactID('business.abn');
    if (abn) {
      this.updateFact({
        productID: factData._productID,
        fact: factData,
        value: abn
      });
    }
    this.abnDisabled = !!abn;
  }

  private isAddressFieldDisabled(factId, sectionName) {
    return _.some(this.copyFieldsSuffixes, (s: any) => factId && factId.endsWith(s));
  }

  private isBusinessAddress(sectionName, sectionFacts) {
    if (this.isCopyDetailsSection(sectionName) && _.keys(this.detailCopies).includes(sectionName)) {
      return _.some(sectionFacts, (f: any) => f && f.id && f.id.match(/.address$/));
    }
    return false;
  }

  // util methods
  private getConfigByType(fact: any) {
    return FactService.getConfigByType(fact);
  }

  private getValue(dataPath: string, valuePath: string, id: string) {
    const facts = _.get(this, dataPath);
    const selectedFact = _.find(facts, { id });
    return _.get(selectedFact, valuePath, null);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private async sendToCRM() {
    const businessSectionFacts = this.uniqueFacts[BUSINESS_SECTION_NAME];
    _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_DETAILS, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(businessSectionFacts)
    .then(() => {
      this.setPardotAvailability(true);
    })
    .catch(() => {
      this.setPardotAvailability(false);
    });
  }
}
